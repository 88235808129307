body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(90deg, rgba(21,26,55,1) 0%, rgba(10,2,36,1) 100%);
}

@font-face {
  font-family: 'Forever Grotesk';
  src: local('Forever Grotesk'), url(./assets/fonts/NNForeverGroteskSTD-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Proto Grotesk';
  src: local('Proto Grotesk'), url(./assets/fonts/ProtoGrotesk-Bold.otf) format('opentype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
